var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("h2", [_vm._v("Remotely Fun Games")]),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              value: _vm.search,
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            on: { input: _vm.onSearchInput }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        _vm._l(_vm.tabs, function(item) {
          return _c(
            "v-tab",
            { key: item, attrs: { disabled: _vm.isLoading("default") } },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 games-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.games,
          pagination: _vm.pagination,
          search: _vm.shouldDoLocalSearch ? _vm.search : undefined,
          "total-items": _vm.shouldDoServerPagination
            ? _vm.totalItemsCount
            : undefined,
          loading: _vm.isLoading("default"),
          "item-key": "id"
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "px-1", staticStyle: { width: "0.1%" } },
                    [
                      _vm.editable(props.item)
                        ? _c(
                            "div",
                            [
                              !!_vm.initializingGame
                                ? _c("v-progress-circular", {
                                    staticClass: "spinner",
                                    attrs: { indeterminate: "" }
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.directGame(props.item)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("flight_takeoff")])],
                                    1
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _c("span", { staticStyle: { "white-space": "nowrap" } }, [
                      _vm._v(_vm._s(props.item.id))
                    ])
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm.editable(props.item)
                      ? _c("div", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.pushToGameEdit(props.item)
                                }
                              }
                            },
                            [_vm._v(_vm._s(props.item.name) + " ")]
                          )
                        ])
                      : _c("div", [_vm._v(" " + _vm._s(props.item.name) + " ")])
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(props.item.date))
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(_vm.getHostName(props.item.hostUserID)))
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(" " + _vm._s(props.item.client) + " ")
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/gamelogin/" + _vm.getKey(props.item) + "?auth=0",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Login ")]
                    ),
                    _c("br"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/gamelogin/" +
                            _vm.getKey(props.item) +
                            "?audit=1&auth=0",
                          target: "_blank"
                        }
                      },
                      [_c("i", [_vm._v("Audit")])]
                    )
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "text-xs-center",
                      attrs: { width: "280px" }
                    },
                    [
                      _vm.editable(props.item)
                        ? _c(
                            "span",
                            [
                              !!_vm.initializingGame
                                ? _c("v-progress-circular", {
                                    staticClass: "spinner",
                                    attrs: { indeterminate: "" }
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.directGame(props.item)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("flight_takeoff")])],
                                    1
                                  ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    link: "",
                                    color: "white",
                                    light: "",
                                    small: "",
                                    fab: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.edit(props.item)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("edit")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            link: "",
                            color: "white",
                            light: "",
                            small: "",
                            fab: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.copy(props.item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("open_in_new")])],
                        1
                      ),
                      _vm.user.super
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                color: "white",
                                light: "",
                                small: "",
                                fab: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openRemoveDialog(props.item)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("delete")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "pageText",
            fn: function(ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Game")])],
                1
              ),
              _c("v-card-text", [
                _vm._v("Are you sure want to delete the game called "),
                _c("b", [_vm._v(_vm._s(_vm.game.name))]),
                _vm._v("?")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove }
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !!_vm.game && _vm.editing
        ? _c(
            "v-dialog",
            {
              staticClass: "game-settings-wrap",
              attrs: { width: "60%", persistent: "" },
              model: {
                value: _vm.editing,
                callback: function($$v) {
                  _vm.editing = $$v
                },
                expression: "editing"
              }
            },
            [
              _c("GameSettings", {
                attrs: {
                  copying: _vm.copying,
                  adding: _vm.adding,
                  game: _vm.game
                },
                on: {
                  update: _vm.getGames,
                  closeGameSettings: function($event) {
                    return _vm.closeEdit()
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        { attrs: { width: "768px" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _vm.copying
                        ? _c("v-icon", [_vm._v("open_in_new")])
                        : _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("edit")
                          ]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.game.name))
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.game
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Name", required: "" },
                                    model: {
                                      value: _vm.game.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "name", $$v)
                                      },
                                      expression: "game.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm5: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Description",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "description", $$v)
                                      },
                                      expression: "game.description"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs10: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Pick Team Text",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.pickTeamText,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "pickTeamText", $$v)
                                      },
                                      expression: "game.pickTeamText"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Game Date", required: "" },
                                    model: {
                                      value: _vm.game.date,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "date", $$v)
                                      },
                                      expression: "game.date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Client", required: "" },
                                    model: {
                                      value: _vm.game.clientID,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "clientID", $$v)
                                      },
                                      expression: "game.clientID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.listTabs,
                                      label: "Game Status",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.game.runStatus,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "runStatus", $$v)
                                      },
                                      expression: "game.runStatus"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Locked" },
                                    model: {
                                      value: _vm.game.locked,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "locked", $$v)
                                      },
                                      expression: "game.locked"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Started" },
                                    model: {
                                      value: _vm.game.started,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "started", $$v)
                                      },
                                      expression: "game.started"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Testing" },
                                    model: {
                                      value: _vm.game.testing,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "testing", $$v)
                                      },
                                      expression: "game.testing"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "Play Audio " },
                                    model: {
                                      value: _vm.game.audio,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "audio", $$v)
                                      },
                                      expression: "game.audio"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-switch", {
                                    attrs: { label: "LogRocket" },
                                    model: {
                                      value: _vm.game.logrocket,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "logrocket", $$v)
                                      },
                                      expression: "game.logrocket"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Potato" },
                                    model: {
                                      value: _vm.game.potato,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "potato", $$v)
                                      },
                                      expression: "game.potato"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "", "mx-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Num of players" },
                                    model: {
                                      value: _vm.game.players,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "players", $$v)
                                      },
                                      expression: "game.players"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          !_vm.game.group
                            ? _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.playerPointsGoal,
                                          label: "Player Goal",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.game.playerPointsGoal,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "playerPointsGoal",
                                              $$v
                                            )
                                          },
                                          expression: "game.playerPointsGoal"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.maxTeamSize,
                                          "item-value": "value",
                                          "item-text": "name",
                                          label: "Max Players Per Team",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.game.maxTeamSize,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "maxTeamSize",
                                              $$v
                                            )
                                          },
                                          expression: "game.maxTeamSize"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.maxNumOfTeams,
                                          "item-value": "value",
                                          "item-text": "name",
                                          label: "Max # of Teams",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.game.maxNumOfTeams,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "maxNumOfTeams",
                                              $$v
                                            )
                                          },
                                          expression: "game.maxNumOfTeams"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.tries,
                                          label: "Default Tries",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.game.numOfTries,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "numOfTries",
                                              $$v
                                            )
                                          },
                                          expression: "game.numOfTries"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm2: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Hex Code",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.game.themeColor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.game,
                                              "themeColor",
                                              $$v
                                            )
                                          },
                                          expression: "game.themeColor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-flex", {
                                    attrs: { xs12: "", sm1: "", "mx-2": "" }
                                  }),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { xs12: "", sm5: "", "mx-2": "" }
                                    },
                                    [
                                      _c("v-img", {
                                        staticStyle: {
                                          float: "left",
                                          "margin-right": "10px"
                                        },
                                        attrs: {
                                          src: _vm.game.image,
                                          width: "125"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.game.image = null
                                          }
                                        }
                                      }),
                                      _c(
                                        "image-uploader",
                                        {
                                          attrs: {
                                            debug: 1,
                                            "max-width": 1200,
                                            quality: 1,
                                            "auto-rotate": true,
                                            preview: false,
                                            "class-name": [
                                              "fileinput",
                                              {
                                                "fileinput--loaded":
                                                  _vm.game.image
                                              }
                                            ],
                                            "output-format": "verbose",
                                            capture: "environment",
                                            accept: ".jpg,.png,.jpeg"
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.setImage(
                                                $event,
                                                _vm.game,
                                                "image"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              ref: "fileGrabber",
                                              attrs: {
                                                slot: "upload-label",
                                                for: "fileInput"
                                              },
                                              slot: "upload-label"
                                            },
                                            [
                                              _c("figure", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      width: "32",
                                                      height: "32",
                                                      viewBox: "0 0 32 32"
                                                    }
                                                  },
                                                  [
                                                    _c("path", {
                                                      staticClass: "path1",
                                                      attrs: {
                                                        d:
                                                          "M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "upload-caption"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.game.image
                                                        ? "Replace Game Image"
                                                        : "Upload Game Image"
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.user.super && _vm.copying
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", "mx-2": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.orgs,
                                      label: "Copy To Organization",
                                      "item-text": "name",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.game.orgID,
                                      callback: function($$v) {
                                        _vm.$set(_vm.game, "orgID", $$v)
                                      },
                                      expression: "game.orgID"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _vm.copying
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Copy")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { loading: _vm.loadingImage, flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Save")]
                          ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.closeEdit()
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }