


































































































































































































































































































































































// TODO: remove when time comes
// @ts-nocheck
// TODO(Andrew): name tab more explicitly not `0`, `1`...
import Vue from "vue"
import mixins from "vue-typed-mixins"
import { mapGetters } from "vuex"
import { debounce } from "lodash"
import uniqid from "uniqid"
import * as moment from "moment"

import type { Game } from "@/types/game"

import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"

import { LoadingMixinFactory, getDefaultLoadingScopes } from "@/mixins/loading"

const GameSettings = () => import("@/components/Game/GameSettings.vue")

function getDefaultPagination() {
  return { page: 1, rowsPerPage: 20 }
}

const tabs = ["All", "Mine", "Blocks", "Masters", "Tournament", "Deleted"]

import { Firebase } from "@/helpers"
import { GameFilterNext } from "@/helpers/game-filter"

export default mixins(
  Vue.extend(LoadingMixinFactory.create(getDefaultLoadingScopes()))
).extend({
  metaInfo: {
    title: "Games"
  },
  components: {
    GameSettings
  },
  data() {
    return {
      games: [] as Game[],
      tries: ["Unlimited", "1", "2", "3", "4", "5"],
      tab: 0,
      initializingGame: false,
      tabs,
      listTabs: [
        "Client Games",
        "Testing",
        "Templates",
        "User Templates",
        "Blocks",
        "Masters",
        "Tournament",
        "Archived",
        "Deleted"
      ],
      hasImage: false,
      imageName: "",
      imageUrl: "",
      imageFile: "",
      loadingImage: false,
      game: {
        name: "",
        description: "",
        maxTeamSize: 6,
        maxNumOfTeams: 3
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      copying: false,
      theTime: moment(new Date()).unix(),
      search: "",
      pagination: getDefaultPagination(),
      totalItemsCount: Infinity,
      behaviors: this.$store.getters.behaviors,
      playTypes: this.$store.getters.playTypes,
      playType: "Team: Speed Matters",
      headers: [
        {
          sortable: false
        },
        {
          text: "Id",
          value: "id",
          align: "left",
          sortable: false
        },
        {
          text: "Game",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          align: "left",
          sortable: false
        },
        {
          text: "Host",
          value: "hostUserID",
          align: "left",
          sortable: false
        },
        {
          text: "Client",
          value: "client",
          align: "left",
          sortable: false
        },
        {
          text: "Game Login",
          value: "code",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters("auth", ["hasPreGame", "token"]),
    ...mapGetters(["hosts", "user"]),
    shouldDoLocalSearch(): boolean {
      return this.isSearchValid && this.tab !== 0
    },
    shouldDoServerPagination(): boolean {
      return this.tab === 0
    },
    isSearchValid(): boolean {
      return this.search.length >= 4
    },
    orgs() {
      var arr = Object.entries(this.$store.getters.orgs)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name
        newArr.push(obj)
      }
      console.log("ALL ORG", newArr)
      return newArr
    },
    maxTeamSize() {
      var p = []
      for (var i = 1; i < 16; i++) {
        p.push({ name: i, value: i })
      }
      return p
    },
    playerPointsGoal() {
      var p = []
      for (var i = 0; i < 2001; i = i + 25) {
        p.push(i)
      }
      return p
    },
    maxNumOfTeams() {
      var p = []
      for (var i = 1; i < 20; i++) {
        p.push({ name: i, value: i })
      }
      return p
    }
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    },
    pagination: {
      handler(newVal, oldVal) {
        if (!this.isSearchValid) {
          const isMovingBack = oldVal.page > newVal.page
          const options = isMovingBack
            ? { startAt: this.games[0]?.id }
            : { endAt: this.games[this.games.length - 1]?.id }
          this.getGames(options)
        }
      },
      deep: true
    },
    search() {
      // Always reset page when search changes
      this.pagination.page = 1
      if (this.shouldDoServerPagination) {
        this.getGames()
      }
    },
    tab() {
      // Always reset search when tab changes
      this.search = ""
      this.games = []
      this.pagination.page = 1
      this.getGames()
    }
  },
  created() {
    this.onSearchInput = debounce(this.onSearchInput, 2000)
  },
  mounted() {
    this.getGames()
  },
  methods: {
    onSearchInput(value: string) {
      this.search = value
    },
    /**
     * This logic will be deleted as soon as we have Firestore.
     * Now it's overcomplicated.
     */
    // TODO(Andrew): remove `if` `else` `else if` hell
    // TODO(Andrew): refactor
    // TODO(Andrew): handle errors
    async getGames(options: { startAt?: string; endAt?: string } = {}) {
      const { orgID } = this.$store.state
      this.setLoading("default", true)
      const service = await this.$services.getService("game")

      if (this.isSearchValid) {
        if (Firebase.isFirebaseKey(this.search)) {
          const game = await service.getGameByID(orgID, this.search)
          console.log(game)
          if (game) {
            this.games = [game]
          } else {
            this.games = []
          }
        } else {
          this.games = await service.getGamesByName(orgID, this.search)
        }
      } else if (this.tab !== 0 && this.tab !== 1) {
        this.games = await service.getGamesByRunStatus(orgID, tabs[this.tab])
      } else if (this.tab === 1) {
        this.games = await service.getGamesByHost(orgID, this.user.id)
      } else {
        const { value, total } = await service.getGames(orgID, {
          startAt: options.startAt,
          endAt: options.endAt,
          page: this.pagination.page,
          pageSize: this.pagination.rowsPerPage
        })

        if (value) {
          this.games = value
        }
        if (total) {
          this.totalItemsCount = total
        }
      }

      this.setLoading("default", false)
    },
    editable(game) {
      if (this.user.super) return true
      if (
        !this.user.super &&
        game.runStatus !== "Masters" &&
        game.runStatus !== "Blocks" &&
        game.runStatus !== "Tournament" &&
        game.runStatus !== "Templates"
      )
        return true
      return false
    },
    getHostName(userID) {
      if (!userID) return
      if (this.hosts[userID]) {
        return this.hosts[userID].firstname
      } else {
        return "Unassigned"
      }
    },
    getKey({ clientID }) {
      return clientID
    },
    async setImage(e, game) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.game.name}.png`
        game.image = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("Game Image Url = " + game.image)
        this.hasImage = true
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async pushToGameEdit({ id: gameID, clientID }: Game) {
      console.log(arguments)
      await this.$store.dispatch("auth/initializeToGame", { gameID, clientID })
      await this.$router.push(`/game/${clientID}/settings`)
    },
    async directGame({ id: gameID, clientID }) {
      if (!gameID) throw new Error("No game ID")
      if (!clientID) throw new Error("No client ID")
      this.initializingGame = true
      try {
        await this.$store.dispatch("auth/initializeToGame", {
          gameID,
          clientID
        })
        const hasPreGame = this.$store.state.auth.hasPreGame
        const game = this.$store.state.game
        const room = game.started ? "game" : "lobby"
        if (hasPreGame) {
          await this.$router.push(`/${room}/${clientID}${gameID}`)
        } else {
          await this.$router.push(`/${room}/${clientID}`)
        }
      } catch (e) {
        console.error(e)
      }
      this.initializingGame = false
    },
    resetForm() {
      this.game = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(game) {
      this.viewing = true
      this.game = game
    },
    closeModalDetail() {
      this.viewing = false
      this.game = null
    },
    openRemoveDialog(game) {
      this.game = game
      this.deleting = true
    },
    remove() {
      this.$store.dispatch("Games/removeGame", this.game)
      this.deleting = false
      this.getGames()
    },
    edit(game) {
      this.copying = false
      this.editing = true
      this.game = game
    },
    closeEdit() {
      this.copying = false
      this.editing = false
    },
    copy(game) {
      this.copying = true
      this.editing = true
      this.game = game
    },
    add() {
      this.editing = true
      this.adding = true
      this.game = {
        name: "",
        description: "",
        group: true
      }
    },
    async update() {
      console.log("updating game", this.game)
      console.log("adding", this.adding)
      console.log("copying", this.copying)
      this.game.addedBy = this.user.name

      // if (this.game.id && this.game.id !== this.game.theKey) {
      //   alert("Game ID error! Please contact your administrator")
      //   console.error(
      //     `Game ID error! Please contact your administrator. ${this.game.id} vs ${this.game.theKey}`
      //   )
      //   return
      // }

      if (this.adding) {
        await this.$store.dispatch("Games/addGame", this.game)
      } else if (this.copying) {
        // only to copy to all orgs

        if (this.game.orgID == "-M7TAB4i47kwrozgRi0-") {
          console.log("ORGS", this.orgs)
          for (var i in this.orgs) {
            this.game.orgID = i
            console.log("ORG TO COPY", i)
            await this.$store.dispatch("Games/copyGame", { game: this.game })
          }
          // console.log("ENTER FOUR LOOP")
        } else {
          await this.$store.dispatch("Games/copyGame", { game: this.game })
        }
      } else {
        await this.$store.dispatch("Games/updateGame", this.game)
      }
      this.editing = false
      this.adding = false
      this.getGames()
    }
  }
})
