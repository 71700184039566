import Vue from "vue"
import { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options"

export class LoadingMixinFactory {
  static create<T extends readonly string[]>(scopes: T) {
    const LoadingMixin: ThisTypedComponentOptionsWithRecordProps<
      Vue,
      { loading: Record<T[number], boolean> },
      {},
      { setLoading(scope: T[number], value: boolean): void },
      Record<string, Function>
    > = {
      data() {
        return {
          loading: scopes.reduce((acc, curr) => {
            acc[curr as T[number]] = false
            return acc
          }, {} as Record<T[number], boolean>)
        }
      },
      methods: {
        // TODO(Andrew): UX friendly timeout
        setLoading(scope: T[number], value: boolean) {
          this.loading[scope] = value
        },
        isLoading(scope: T[number]) {
          return this.loading[scope]
        }
      }
    }

    return LoadingMixin
  }
}

// TODO(Andrew): move to default argument
export function getDefaultLoadingScopes() {
  return ["default"] as const
}
